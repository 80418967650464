import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const validateForm = () => {
    const newErrors = {
      name: name.trim() ? '' : 'Name is required',
      email: email.trim() ? '' : 'Email is required',
      message: message.trim() ? '' : 'Message is required'
    };
    setErrors(newErrors);
    return !newErrors.name && !newErrors.email && !newErrors.message;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return; // Stop form submission if validation fails
    }
  
    const formData = {
      name,
      email,
      phone: phone || '-',
      message,
    };
  
    try {
      const response = await fetch('https://cloudclou.net:5000/send-email', { // Adjust URL if needed
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        setSuccess(true);
        setErrors({
          name: '',
          email: '',
          message: ''
        }); // Clear errors on successful submission
      } else {
        const errorText = await response.text();
        console.error('Error sending data:', errorText);
        // Optional: Display a user-friendly error message
      }
    } catch (error) {
      console.error('Error connecting to server', error);
    }
  };
  

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div className="input-container">
        <div className="label">E-MAIL*</div>
        <textarea
          className={`text-window email-window ${errors.email ? 'error' : ''}`}
          placeholder=""
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrors((prev) => ({ ...prev, email: '' }));
          }}
        />
        {errors.email && <div className="error-message">{errors.email}</div>}
      </div>
      <div className="horizontal-container">
        <div className="input-container name-container">
          <div className="label">NAME*</div>
          <textarea
            className={`text-window name-window ${errors.name ? 'error' : ''}`}
            placeholder=""
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setErrors((prev) => ({ ...prev, name: '' }));
            }}
          />
          {errors.name && <div className="error-message">{errors.name}</div>}
        </div>
        <div className="input-container">
          <div className="label">PHONE NUMBER</div>
          <input
            type="tel"
            className="input-phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder=""
          />
        </div>
      </div>
      <div className="input-container">
        <div className="label">MESSAGE*</div>
        <textarea
          className={`text-window message-window ${errors.message ? 'error' : ''}`}
          placeholder=""
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setErrors((prev) => ({ ...prev, message: '' }));
          }}
        />
        {errors.message && <div className="error-message">{errors.message}</div>}
        <div className="necessary-note">*necessary</div>
      </div>
      <button className="contact-button" type="submit">
        CONTACT US
      </button>
      {success && <p className="feedback">Thank you for your feedback!</p>}
      <div className="data-admin-note">
        The data controller responsible for the personal data collected through this service is cloud clou, located at Cloud Clou Kft., 2636 Tésa, Ady Endre utca 11. For any questions regarding your personal data, please contact us at ankupr@cloudclou.net.
      </div>
    </form>
  );
};

export default ContactForm;
