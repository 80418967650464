// App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import Header from './components/Header';
import ExampleUsage from './components/ExampleUsage';
import ContactUsPage from './contactus/ContactUsPage'; // Import the ContactUsPage component
import './App.css';
import translations from './context/translations';


import { Maturity, Migration, Infrastructure, Economics, Multicloud, Partners } from './components/CombinedHomes';


import Footer from './components/Footer'; // Импортируем компонент Footer

const App = () => {
    const [language, setLanguage] = useState('ru'); // Change to 'en' for English by default

    return (
        <Router>
            <div className="app">
                <Header language={language} setLanguage={setLanguage} />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<ExampleUsage language={language} />} />
                        <Route path="/contact-us" element={<ContactUsPage />} />
                    </Routes>
                </div>
                <Routes>
                <Route path="/maturity-assessment" element={<Maturity language={language} />} />
                        <Route path="/migration" element={<Migration language={language} />} />
                        <Route path="/infrastructure" element={<Infrastructure language={language} />} />
                        <Route path="/economics" element={<Economics language={language} />} />
                        <Route path="/multicloud" element={<Multicloud language={language} />} />
                        <Route path="/partners" element={<Partners language={language} />} />
                        </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
