import React, { useRef } from 'react';
import { Home, SwitchPage, Maturity, Migration, Infrastructure, Partners, Economics, Connection, Multicloud} from './CombinedHomes'; // Updated import for Partners

const ExampleUsage = ({ language }) => {
    const MaturityRef = useRef(null);
    const MigrationRef = useRef(null);
    const InfrastructureRef = useRef(null);
    const EconomicsRef = useRef(null);
    const MulticloudRef = useRef(null);
    

    const scrollToSection = (section) => {
        let targetRef;
        
        if (section === 'Maturity') {
            targetRef = MaturityRef;
        } else if (section === 'Migration') {
            targetRef = MigrationRef;
        } else if (section === 'Infrastructure') {
            targetRef = InfrastructureRef;
        } else if (section === 'Economics') {
            targetRef = EconomicsRef;
        } else if (section === 'Multicloud') {
            targetRef = MulticloudRef;
        }
        
        if (targetRef && targetRef.current) {
            const offset = 50; // Adjust this value as needed
            const elementPosition = targetRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            <Home language={language} />
            <SwitchPage language={language} onButtonClick={scrollToSection} />
            <div ref={MaturityRef}>
                <Maturity language={language} />
            </div>
            <div ref={MigrationRef}>
                <Migration language={language} />
            </div>
            <div ref={InfrastructureRef}>
                <Infrastructure language={language} />
            </div>
            <div ref={EconomicsRef}>
                <Economics language={language} />
            </div>
            <div ref={MulticloudRef}>
                <Multicloud language={language} />
            </div>

            <Partners language={language} />
            
            
        </div>
    );
};

export default ExampleUsage;
