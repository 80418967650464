import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import logo from '../assets/CloudClou.png';
import translations from '../context/translations';

const Header = ({ language }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Function to smoothly scroll to the footer
    const scrollToFooter = () => {
        const footerElement = document.getElementById('contact-footer');
        if (footerElement) {
            footerElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className="header">
            <div className="header-content">
                <div className="header-logo">
                    <a href="/">
                        <img src={logo} alt="Logo" className="header-logo" />
                    </a>
                </div>

                {!isMobile && (
                    <nav className="header-nav">
                        <ul>
                            <li>
                                <span className="cloudclou-text">cloud clou</span>
                            </li>
                        </ul>
                    </nav>
                )}

                <div className={`header-controls ${isMobile ? 'header-controls-mobile' : ''}`}>
                    <button className="header-contact-button" onClick={scrollToFooter}>
                        {translations[language].contactUs}
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
