import React from 'react';
import ContactForm from './ContactForm';
import './ContactUsPage.css';


const ContactUsPage = () => {
    return (
        <div className="contact-us-content">
            <div className="get-in-touch">Let's get in touch</div>
            <ContactForm />
            
        </div>
    );
}

export default ContactUsPage;
