// translations.js
const translations = {
    en: {
        contactUs: 'CONTACT US',

        homeTitle: 'We know the cloud',
        homeText: 'With over 15 years of experience and partnerships with top industry leaders, we are your trusted cloud experts. Our team will assess your current cloud infrastructure, recommend customized solutions, and help you reduce costs, ensuring your business operates smoothly and efficiently in the cloud',

        text40: 'Choosing the Right Cloud Solution',
        text30: 'Text size 30px',
        text20: 'Text size 20px',
        partnerButton: 'PARTNER PLATFORMS',

        microsoftDescription: 'Microsoft is a global technology leader that develops and sells software, hardware, and services. Known for its flagship products like Windows, Office, and Azure, Microsoft enables businesses and individuals to enhance productivity, embrace cloud computing, and innovate with cutting-edge technologies such as AI and machine learning. The company also has a strong presence in gaming through its Xbox platform.',
        
        buttonText: 'Button Text',
        letsGetInTouch: "Let's get in touch",

        button1: "Maturity Assessment Service",
        button2: "Cloud Migration Assessment",
        button3: "Cloud Infrastructure Migration Service",
        button4: "Cloud economics",
        button5: "Multicloud assessment",


    },
    ru: {
        contactUs: 'CONTACT US',
        
        homeTitle: 'We know the cloud',

        homeText: 'With over 15 years of experience and partnerships with top industry leaders, we are your trusted cloud experts. Our team will assess your current cloud infrastructure, recommend customized solutions, and help you reduce costs, ensuring your business operates smoothly and efficiently in the cloud',

        text40: 'Choosing the Right Cloud Solution',

        text20: 'We provide access to three different public cloud platforms, including our proprietary Cloudclou Platform, designed and managed by our in-house experts. Choose the platform that best aligns with your specific needs. In addition, we develop customized cloud migration strategies that address your unique business challenges and goals. Whether you require hybrid solutions, multi-cloud environments, or application migration services, we are dedicated to bringing your vision to life.',
        partnerButton: 'PARTNER PLATFORMS',
        buttonText: 'Текст кнопки',
        letsGetInTouch: "Let's get in touch",
    },
};


export default translations;
