// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-container {
    display: flex;
    justify-content: space-between;
    /*margin-top: 50px; /* Add space before the container */
    padding: 20px;
    /*padding-bottom: 150px;*/
    /*background-color: #dee9f5;*/
    
}

.bottom-sub-container {
    flex: 1 1;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
}

.text-container-connection{
    padding-left: 20%;
    padding-right: 20%;
}

/* .text-container-connection {
    padding: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation 
}*/

/* .text-container-connection p {
    margin: 0;
    font-size: 16px;
    color:  
}*/

@media (max-width: 768px) {
    .bottom-container {
        flex-direction: column;
    }
    
    .bottom-sub-container {
        margin: 15px 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Hometext.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uDAAuD;IACvD,aAAa;IACb,yBAAyB;IACzB,6BAA6B;;AAEjC;;AAEA;IACI,SAAO;IACP,cAAc;IACd,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;;;;;;EAME;;AAEF;;;;EAIE;;AAEF;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":[".bottom-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    /*margin-top: 50px; /* Add space before the container */\r\n    padding: 20px;\r\n    /*padding-bottom: 150px;*/\r\n    /*background-color: #dee9f5;*/\r\n    \r\n}\r\n\r\n.bottom-sub-container {\r\n    flex: 1;\r\n    margin: 0 15px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.text-container-connection{\r\n    padding-left: 20%;\r\n    padding-right: 20%;\r\n}\r\n\r\n/* .text-container-connection {\r\n    padding: 10px;\r\n    background-color: #ffffff;\r\n    margin-bottom: 10px;\r\n    border-radius: 5px;\r\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for elevation \r\n}*/\r\n\r\n/* .text-container-connection p {\r\n    margin: 0;\r\n    font-size: 16px;\r\n    color:  \r\n}*/\r\n\r\n@media (max-width: 768px) {\r\n    .bottom-container {\r\n        flex-direction: column;\r\n    }\r\n    \r\n    .bottom-sub-container {\r\n        margin: 15px 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
