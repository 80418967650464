import React from 'react';
import { Link } from 'react-router-dom'; // Импортируйте Link
import './Footer.css';
import CloudClou from './combinedhome/img/CloudClouWhite.png';

const Footer = () => {
  return (
    <footer id="contact-footer" className="footer">
      <div className="waves">
        <div className="wave" id="wave1"></div>
        <div className="wave" id="wave2"></div>
        <div className="wave" id="wave3"></div>
        <div className="wave" id="wave4"></div>
      </div>
      
      <div className="footer-content">
        {/* Footer Logo */}
        <div className="footer-column footer-logo">
          <img src={CloudClou} alt="CloudClou" />
        </div>

        {/* Services Column */}
        <div className="footer-column">
          <ul className="menu2">
            <li className="menu__item">
              <p className="menu__link">Services</p>
            </li>
            <li className="menu__item">
              <Link className="menu__link" >Maturity Assessment Service</Link> 
            </li>
            <li className="menu__item">
              <Link className="menu__link" >Cloud Migration Assessment</Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link" >Cloud Infrastructure Migration Service</Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link" >Cloud Economics</Link>
            </li>
            <li className="menu__item">
              <Link className="menu__link">Multicloud Assessment</Link>
            </li>
          </ul>
        </div>

      {/* 
        to="/maturity-assessment"
        to="/migration"
        to="/infrastructure"
        to="/economics"
        to="/multicloud"
      */}



        {/* Contact Column */}
        <div className="footer-column">
          <ul className="menu2">
            <li className="menu__item">
              <a className="menu__link">Contact</a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="https://t.me/the_CloudClou" target="_blank" rel="noopener noreferrer">Telegram</a>
              <p>@the_CloudClou</p>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="https://wa.me/36203154303" target="_blank" rel="noopener noreferrer">WhatsApp</a>
              <p>+36 20 315 4303</p>
            </li>
            <li className="menu__item">
              <a className="menu__link">Maps</a>
              <p>Cloud Clou Kft., 2636 Tésa, Ady Endre utca 11</p>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="mailto:ankupr@cloudclou.net">Email</a>
              <p>ankupr@cloudclou.net</p>
            </li>
          </ul>
        </div>
      </div>
      
      <p>Cloud Clou &copy; 2025</p>
    </footer>
  );
};

export default Footer;
